






import { Component, Vue } from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import XTabs from "@/components/SimpleTabs.vue";
import DocumentsTabContent from "@/components/for-documents-view/DocumentsTabContent.vue";
import ContractDocumentsTabContent from "@/components/for-documents-view/ContractDocumentsTabContent.vue";
import ContractDocumentsEDOTabContent from "@/components/for-documents-view/ContractDocumentsEDOTabContent.vue";
import {mapGetters, mapState} from "vuex";

@Component({
  components: {
    Page,
    XTabs,
    DocumentsTabContent,
    ContractDocumentsTabContent,
    ContractDocumentsEDOTabContent,
  }
})
class Documents extends Vue {
  titles = ["Документы", "Документы договора", "Документы ЭДО"];

  components = [DocumentsTabContent, ContractDocumentsTabContent, ContractDocumentsEDOTabContent];
}

export default Documents;
