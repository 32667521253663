




















import {Component, Prop} from "vue-property-decorator";
import XDropdown from "./SimpleDropdown.vue";
import {AccountingPointIndications} from "@/models/accounting-point";

@Component({
  components: { XDropdown },
})
class ActionDropdownEDO extends XDropdown {
  @Prop({required: true}) readonly status!: string;
  @Prop({required: true}) readonly type!: string;

  typesAvailableToSinging = [
    'Акт приема передачи', 'Акт сверки', 'Ведомость СКУЭ'
  ]
  itemProps = [
    {
      icon: "mdi-send",
      title: "Отправить email",
      evtName: "send",
    },
    {
      icon: "mdi-download",
      title: "Скачать",
      evtName: "download",
    }
  ];

  mounted(){
    if(!this.typesAvailableToSinging.includes(this.type)){
      return
    }

    if(this.status == "Отклонен"){
      this.itemProps.push(
        {
          icon: "mdi-file-document-check-outline",
          title: "Принять",
          evtName: "accept",
        },
      )
    }
    if(this.status == ""){
      this.itemProps.push(
        {
          icon: "mdi-file-document-check-outline",
          title: "Принять",
          evtName: "accept",
        },
        {
          icon: "mdi-file-document-minus-outline",
          title: "Отклонить",
          evtName: "cancel",
        },
      )
    }
  }
}

export default ActionDropdownEDO;
